import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { NavLink, useRouteLoaderData } from 'react-router-dom';

import { selectDarkMode } from 'reducers/ui';

import styles from './index.module.css';
import hasPermission from '../../helpers/permissions';

export default () => {
  const darkMode = useSelector(selectDarkMode);
  const classes = classNames({ 'bp4-dark': darkMode });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { getUser: { permissions } } = useRouteLoaderData('root') as any;

  const isActiveClass = ({ isActive }: { isActive: boolean }) => {
    return isActive ? styles.activeNavMenuItem : undefined;
  };

  return (
    <div className={classes}>
      <ul className={styles.navMenu}>
        <li key="upper1">
          <NavLink to="/" className={isActiveClass} end>Setups</NavLink>
          {hasPermission('suit_write', permissions) ? (
            <ul className={styles.subMenu} key="subMenu1">
              <li key="Setups">
                <NavLink to="/setups/fields" className={isActiveClass}>Setup Fields</NavLink>
              </li>
              <li key="SUITs">
                <NavLink to="/setups/suits" className={isActiveClass}>SUITs</NavLink>
              </li>
            </ul>
          ) : null}
        </li>
        <li key="upper2">
          <NavLink to="/runs/compare" className={isActiveClass} end>Runs</NavLink>
          <ul className={styles.subMenu} key="subMenu2">
            <li key="Run Compare">
              <NavLink to="/runs/compare" className={isActiveClass}>Run Compare</NavLink>
            </li>
            {hasPermission('ruit_write', permissions) ? (
              <>
                <li key="Run Fields">
                  <NavLink to="/runs/fields" className={isActiveClass}>Run Fields</NavLink>
                </li>
                <li key="RUITs">
                  <NavLink to="/runs/ruits" className={isActiveClass}>RUITs</NavLink>
                </li>
              </>
            ) : null}
          </ul>
        </li>
        <li key="upper3">
          <NavLink to="/sims" className={isActiveClass} end>Sims</NavLink>
          <ul className={styles.subMenu} key="subMenu3">
            <li key="Reporting">
              <NavLink to="/sims/reporting" className={isActiveClass}>Reporting</NavLink>
            </li>
            <li key="Documents">
              <NavLink to="/sims/documents" className={isActiveClass}>Documents</NavLink>
            </li>
            <li key="Worksheets">
              <NavLink to="/sim-worksheets" className={isActiveClass}>Worksheets</NavLink>
            </li>
            <li key="Sweeps">
              <NavLink to="/sims/sweeps" className={isActiveClass}>Sweeps</NavLink>
            </li>
            <li key="Metrics">
              <NavLink to="/sims/metrics" className={isActiveClass}>Metrics</NavLink>
            </li>
          </ul>
        </li>
        <li key="upper4">
          <NavLink to="/environments" className={isActiveClass} end>Environments</NavLink>
        </li>
        <li key="upper5">
          <NavLink to="/parts" className={isActiveClass} end>Parts</NavLink>
          <ul className={styles.subMenu} key="subMenu4">
            <li key="Properties">
              <NavLink to="/parts/properties" className={isActiveClass}>Properties</NavLink>
            </li>
            <li key="Configs">
              <NavLink to="/parts/configs" className={isActiveClass}>Configs</NavLink>
            </li>
          </ul>
        </li>
        {/* <li>
          <NavLink to="/admin" className={isActiveClass} end>Admin</NavLink>
          <ul className={styles.subMenu}>
            <li>
              <NavLink to="/admin/permissions" className={isActiveClass}>Permissions</NavLink>
            </li>
          </ul>
        </li> */}
      </ul>
    </div>
  );
};
