import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useLocation, useRouteLoaderData  } from 'react-router-dom';
import { Tooltip2 } from '@blueprintjs/popover2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import _ from 'lodash';

import { selectDarkMode } from 'reducers/ui';
import { selectSetupCompareState } from 'reducers/setupCompare';

import styles from './index.module.css';
import hasPermission from '../../helpers/permissions';

export default () => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const pathName =  (location.pathname.split('/').pop() || 'setup');// pathSegments.pop() || pathSegments.pop() || 'setup';
  const validPaths = ['setup', 'springs', 'bump-stops', 'shocks', 'tires', 'run-log', 'compare'];
  const [isOpen, setIsOpen] = useState(false);
  const [ActiveButton, setActiveButton] = useState(validPaths.includes(pathName) ? pathName : 'setup'); // Set initial ActiveButton base on path name
  const darkMode = useSelector(selectDarkMode);
  const activeSetupCompareState = useSelector(selectSetupCompareState);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { getUser: { permissions } } = useRouteLoaderData('root') as any;

  const toggleIsOpen = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen, setIsOpen]);

  const containerClasses = classNames(styles.container, {
    [styles.dark]: darkMode,
    [styles.collapsed]: !isOpen,
  });

  const handleButtonClick = (buttonId: string, path: string) => {
    if (['compare', 'springs', 'shocks', 'bump-stops'].includes(buttonId)) {
      const baselineSetupId = Number(params.branchId);

      if (activeSetupCompareState[baselineSetupId] && activeSetupCompareState[baselineSetupId].length) {
        navigate(`${buttonId}?ids=${_.join(activeSetupCompareState[baselineSetupId])}`);
      } else {
        navigate(buttonId);
      }
    } else {
      navigate(path);
    }
    setActiveButton(buttonId);
  };

  return (
    <div className={containerClasses}>
      <div className={styles.navButtonContainer}>
        <Tooltip2 content="Setup" disabled={isOpen}>
          <button className={classNames(styles.navButton, { [styles.highlighted]: ActiveButton === 'setup' })} onClick={() => handleButtonClick('setup', '')} type="button">
            <span>
              <FontAwesomeIcon fixedWidth icon="wrench" size="lg" />
            </span>
            <span className={styles.navButtonText}>Setup</span>
          </button>
        </Tooltip2>
        <Tooltip2 content="Springs" disabled={isOpen}>
          <button className={classNames(styles.navButton, { [styles.highlighted]: ActiveButton === 'springs' })} onClick={() => handleButtonClick('springs', 'springs')} type="button">
            <span>
              <FontAwesomeIcon fixedWidth icon="reel" size="lg" />
            </span>
            <span className={styles.navButtonText}>Springs</span>
          </button>
        </Tooltip2>
        <Tooltip2 content="Bump Stops" disabled={isOpen}>
          <button className={classNames(styles.navButton, { [styles.highlighted]: ActiveButton === 'bump-stops' })} onClick={() => handleButtonClick('bump-stops', 'bump-stops')} type="button">
            <span>
              <FontAwesomeIcon fixedWidth icon="car-bump" size="lg" />
            </span>
            <span className={styles.navButtonText}>Bump Stops</span>
          </button>
        </Tooltip2>
        <Tooltip2 content="Shocks" disabled={isOpen}>
          <button className={classNames(styles.navButton, { [styles.highlighted]: ActiveButton === 'shocks' })} onClick={() => handleButtonClick('shocks', 'shocks')} type="button">
            <span>
              <FontAwesomeIcon fixedWidth icon="car-bolt" size="lg" />
            </span>
            <span className={styles.navButtonText}>Shocks</span>
          </button>
        </Tooltip2>
        {/* This is a temp restriction to tires view until Nate says otherwise */}
        {hasPermission('tires_view_access', permissions) ? (
          <Tooltip2 content="Tires" disabled={isOpen}>
            <button className={classNames(styles.navButton, { [styles.highlighted]: ActiveButton === 'tires' })} onClick={() => handleButtonClick('tires', 'tires')} type="button">
              <span>
                <FontAwesomeIcon fixedWidth icon="tire" size="lg" />
              </span>
              <span className={styles.navButtonText}>Tires</span>
            </button>
          </Tooltip2>
        ) : null}
        <Tooltip2 content="Run Log" disabled={isOpen}>
          <button className={classNames(styles.navButton, { [styles.highlighted]: ActiveButton === 'run-log' })} onClick={() => handleButtonClick('run-log', 'run-log')} type="button">
            <span>
              <FontAwesomeIcon fixedWidth icon="notebook" size="lg" />
            </span>
            <span className={styles.navButtonText}>Run Log</span>
          </button>
        </Tooltip2>
        <Tooltip2 content="Compare" disabled={isOpen}>
          <button className={classNames(styles.navButton, { [styles.highlighted]: ActiveButton === 'compare' })} onClick={() => handleButtonClick('compare', 'compare')} type="button">
            <span>
              <FontAwesomeIcon fixedWidth icon="code-compare" size="lg" />
            </span>
            <span className={styles.navButtonText}>Compare</span>
          </button>
        </Tooltip2>
      </div>
      <div>
        <button
          className={classNames(styles.navButton, styles.expandButton)}
          onClick={() => toggleIsOpen()}
          title="Expand"
          type="button"
          aria-label="expand"
        >
          <FontAwesomeIcon fixedWidth icon={isOpen ? 'arrow-left-from-line' : 'arrow-right-to-line'} size="lg" />
        </button>
      </div>
    </div>
  );
};
