import React from 'react';
import { Navigate, useRouteLoaderData } from 'react-router-dom';
import hasPermission from '../../helpers/permissions';

interface PrivateRouteProps {
  permissionToCheck: string;
  children: JSX.Element;
}

const PrivateRoute = ({ permissionToCheck, children }: PrivateRouteProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { getUser: { permissions } } = useRouteLoaderData('root') as any;

  // If the user does not have permission, redirect to main setup summary page
  if (!hasPermission(permissionToCheck, permissions)) {
    return <Navigate to="/" />;
  }

  // Otherwise, render the child components
  return children;
};

export default PrivateRoute;
