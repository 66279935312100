import { SetupField, SweepParameter, SweepParameterApplicationMethod, SweepParameterGenerationMethod } from 'graphql/generated/graphql';
import styles from './index.module.css';
import { useFormContext } from 'react-hook-form';
import { RHFCheckbox, RHFNumericInput, RHFSelect } from 'components/RHFInputs';
import { toString } from 'lodash';
import { useEffect, useState } from 'react';
import { Intent, Button } from '@blueprintjs/core';

type SweepParameterRowProps = {
  sweepParameter: Partial<SweepParameter>;
  index: number;
  setupFields: SetupField[];
  prefix?: string;
  isInGroup?: boolean;
}

const applicationMethods = [
  { label: 'Delta', value: SweepParameterApplicationMethod.DELTA },
  { label: 'Absolute', value: SweepParameterApplicationMethod.ABSOLUTE },
];

const generationMethods = [
  { label: 'Step', value: SweepParameterGenerationMethod.STEP },
  { label: 'Range', value: SweepParameterGenerationMethod.RANGE },
];

const SweepParameterRow = ({ sweepParameter, index, setupFields, prefix = '', isInGroup = false } : SweepParameterRowProps) => {
  const { control, watch, getValues, setValue } = useFormContext();
  const [parameterPath, setParameterPath] = useState<string>(sweepParameter.path ?? '');

  const setupFieldItems = setupFields.map(s => {
    return { label: s.label, value: s.label };
  });

  const setupFieldName = watch(`${prefix}parameters.${index}.name`);

  useEffect(() => {
    const setupFieldLabel = getValues(`${prefix}parameters.${index}.name`);
    const setupField = setupFields.find(s => s.label === setupFieldLabel);
    if (setupField) {
      setParameterPath(setupField.path);
      setValue(`${prefix}parameters.${index}.path`, setupField.path);
    }
  }, [setupFieldName]);

  const handleRemoveParameter = () => {
    const currentSweepParameters = getValues(`${prefix}parameters`) ?? [];
    currentSweepParameters.splice(index, 1);
    setValue(`${prefix}parameters`, currentSweepParameters);
  };

  return (
    <div className={styles.inputRow}>
      {!isInGroup && (
        <RHFCheckbox
          controllerProps={{
            control,
            name: `${prefix}parameters.${index}.enabled`,
          }}
          checkboxProps={{
            className: styles.inlineCheckbox,
            checked: sweepParameter.enabled,
          }}
        />
      )}
      <RHFNumericInput
        controllerProps={{
          control,
          name: `${prefix}parameters.${index}.name`,
        }}
        inputProps={{
          fill: true,
          className: styles.inline,
          value: toString(sweepParameter.name),
        }}
        useStringValue
      />
      <div className={styles.inlineButton}>
        <RHFSelect
          controllerProps={{
            control,
            name: `${prefix}parameters.${index}.name`,
          }}
          items={setupFieldItems}
          selectProps={{
            fill: true,
            filterable: true,
          }}
          buttonProps={{
            rightIcon: 'more',
            text: '',
          }}
        />
      </div>
      <RHFNumericInput
        controllerProps={{
          control,
          name: `${prefix}parameters.${index}.path`,
        }}
        inputProps={{
          fill: true,
          className: styles.inline,
          value: toString(parameterPath),
        }}
        useStringValue
      />
      <div className={styles.inlineSelect}>
        <RHFSelect
          controllerProps={{
            control,
            name: `${prefix}parameters.${index}.param_application_method`,
          }}
          items={applicationMethods}
          fill
        />
      </div>
      {isInGroup && (
        <div className={styles.inlineSelect}>
          <RHFSelect
            controllerProps={{
              control,
              name: `${prefix}parameters.${index}.param_generation_method`,
            }}
            items={generationMethods}
            fill
          />
        </div>
      )}
      <RHFNumericInput
        controllerProps={{
          control,
          name: `${prefix}parameters.${index}.min`,
        }}
        inputProps={{
          fill: true,
          className: styles.inlineSmall,
          value: watch(`${prefix}parameters.${index}.min`),
        }}
        useStringValue
      />
      {isInGroup && (
        <RHFNumericInput
          controllerProps={{
            control,
            name: `${prefix}parameters.${index}.inc`,
          }}
          inputProps={{
            fill: true,
            className: styles.inlineSmall,
            value: watch(`${prefix}parameters.${index}.inc`),
          }}
          useStringValue
        />
      )}
      <RHFNumericInput
        controllerProps={{
          control,
          name: `${prefix}parameters.${index}.max`,
        }}
        inputProps={{
          fill: true,
          className: styles.inlineSmall,
          value: watch(`${prefix}parameters.${index}.max`),
        }}
        useStringValue
      />
      <div className={styles.inlineButton}>
        <Button
          intent={Intent.DANGER}
          type="button"
          icon="cross"
          minimal
          onClick={handleRemoveParameter}
        />
      </div>
    </div>
  );
};

export default SweepParameterRow;
