import { Button, Intent } from '@blueprintjs/core';
import styles from './index.module.css';
import React, {  } from 'react';
import AppToaster from 'helpers/toaster';
import { SweepPayload } from './types';

interface Props {
  setSweepPayload: (input: SweepPayload) => void;
}

const ImportSweepButton = (props: Props) => {
  const { setSweepPayload } = props;

  // Function to read the file and parse JSON
  const readFile = (file: File) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      try {
        const result = event.target?.result;
        if (typeof result === 'string') {
          const sweepObj = JSON.parse(result) as SweepPayload;
          setSweepPayload(sweepObj);
        } else {
          throw new Error('File content is not a string');
        }
      } catch (error) {
        AppToaster.show({
          intent: Intent.DANGER,
          message: 'Error parsing JSON!',
        });
      }
    };
    reader.readAsText(file);
  };

  // Function to handle file selection
  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Access the file from the input event
    const file = e.target.files && e.target.files[0];
    if (file) {
      // Check if the file type is JSON
      if (file.type === 'application/json') {
        readFile(file);
      } else {
        AppToaster.show({
          intent: Intent.DANGER,
          message: 'Please upload a JSON file.',
        });
      }
    }
  };

  return (
    <>
      <Button
        className={styles.saveButton}
        intent={Intent.NONE}
        type="button"
        text="Import Payload"
        icon="import"
        fill
        onClick={() => {
          const inputFileElement = document.getElementById('file-input') as HTMLInputElement;
          if (inputFileElement) {
            inputFileElement.click();
          }
        }}
      />
      <input
        type="file"
        onChange={handleFileInput}
        style={{ display: 'none' }}
        id="file-input"
        accept=".json"
      />
    </>
  );
};

export default ImportSweepButton;
