import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useRouteLoaderData } from 'react-router-dom';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { H3, Intent, Dialog, DialogBody, DialogFooter, Button, InputGroup } from '@blueprintjs/core';
import _, { cloneDeep, find } from 'lodash';
import classNames from 'classnames';
import { format } from 'date-fns';

import Table, { BulkRowActions, ParamsChangeFn, RowActions } from 'components/Table';
import {
  CreateRUITInput,
  RUIT,
  useCreateRUITMutation,
  useBulkDeleteRUITsMutation,
  useCloneRUITMutation,
  useDeleteRUITMutation,
  useRUITManagementLazyQuery,
} from 'graphql/generated/graphql';
import AppToaster from 'helpers/toaster';
import { useAlert } from 'components/Alert';
import { selectDarkMode } from 'reducers/ui';
import { selectRuitsSummaryView, tableViewSlice } from 'reducers/tableView';
import CreateRUITDialog from './createRUITDialog';
import styles from './index.module.css';
import Select from 'components/Select';
import { teamSelectItems, seriesItems, organizationSelectItems } from '../../constants';

const columnHelper = createColumnHelper<RUIT>();
const columns = [
  columnHelper.accessor('name', {
    header: 'Name',
    cell: info => info.getValue(),
    enableColumnFilter: true,
  }),
  columnHelper.accessor('description', {
    header: 'Description',
    cell: info => info.getValue(),
    enableColumnFilter: true,
  }),
  columnHelper.accessor('created_at', {
    header: 'Created',
    cell: info => {
      const value = info.getValue() as string;
      return format(new Date(value), 'MM/dd/yy HH:mm:ss');
    },
  }),
  columnHelper.accessor('updated_at', {
    header: 'Modified',
    cell: info => {
      const value = info.getValue() as string;
      return format(new Date(value), 'MM/dd/yy HH:mm:ss');
    },
  }),
  columnHelper.accessor('last_modified_by', {
    header: 'Last Modified By',
    cell: info => info.getValue(),
    enableColumnFilter: true,
  }),
] as ColumnDef<RUIT>[];

export default () => {
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState<RUIT[]>([]);
  const [isCloneModalOpen, setCloneModalOpen] = useState(false);
  const [isCreateModalOpen, setCreateModalOpen] = useState(false);
  const cloneName = useRef<HTMLInputElement>(null);
  const [cloneSource, setCloneSource] = useState<RUIT>();
  const navigate = useNavigate();
  const alert = useAlert();
  const darkMode = useSelector(selectDarkMode);
  const { tableFilters, tableSorting } = useSelector(selectRuitsSummaryView);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { getUser: { teams, organizations } } = useRouteLoaderData('root') as any;

  const [getRUITs, { data, refetch }] = useRUITManagementLazyQuery({
    fetchPolicy: 'cache-and-network',
  });
  useEffect(() => {
    if (data) setTableData(data.ruits.rows);
  }, [data]);

  const [deleteRUIT] = useDeleteRUITMutation();
  const [bulkDeleteRUITs] = useBulkDeleteRUITsMutation();
  const [cloneRUIT] = useCloneRUITMutation();
  const [createRUIT] = useCreateRUITMutation();

  const bulkRowActions: BulkRowActions<RUIT> = [{
    intent: Intent.DANGER,
    label: 'Delete',
    value: rows => {
      const content = (
        <>
          <p>Delete these?</p>
          <ul>
            {rows.map(r => <li>{r.original.name}</li>)}
          </ul>
        </>
      );
      alert.showAlert(content, {
        intent: Intent.DANGER,
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
      }).then((yes) => {
        if (!yes) return;

        bulkDeleteRUITs({
          variables: { ids: rows.map(r => r.original.id) },
          onCompleted: () => {
            AppToaster.show({
              intent: Intent.SUCCESS,
              message: 'RUIT(s) successfully deleted',
            });
            rows.forEach(r => r.toggleSelected());
            refetch();
          },
          onError: e => {
            AppToaster.show({
              intent: Intent.DANGER,
              message: `Error deleting RUIT(s): ${e.message}`,
            });
          },
          update: (cache, { data: mutationData }) => {
            if (mutationData?.deleteCount) {
              // Evicts all references to the deleted objects from the cache
              const ids = rows.map(r => cache.identify(r.original));
              ids.forEach(id => cache.evict({ id }));
              cache.gc();
            }
          },
        });
      });
    },
  }];
  const rowActions: RowActions<RUIT> = [{
    label: 'Edit',
    value: row => navigate(`/runs/ruit/${row.original.id}`),
  }, {
    label: 'Clone',
    value: row => {
      setCloneSource(row.original);
      setCloneModalOpen(true);
      cloneName.current?.focus();
    },
  }, {
    intent: Intent.DANGER,
    label: 'Delete',
    value: row => {
      alert.showAlert(`Delete "${row.original.name}"?`, {
        intent: Intent.DANGER,
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
      }).then((yes) => {
        if (!yes) return;
        deleteRUIT({
          variables: { id: row.original.id },
          onCompleted: () => {
            AppToaster.show({
              intent: Intent.SUCCESS,
              message: 'RUIT successfully deleted',
            });
            if (row.getIsSelected()) row.toggleSelected();
            refetch();
          },
          onError: e => {
            AppToaster.show({
              intent: Intent.DANGER,
              message: `Error deleting RUIT: ${e.message}`,
            });
          },
          update: (cache, { data: mutationData }) => {
            if (mutationData?.deleteCount) {
              // Evicts all references to the deleted object from the cache
              cache.evict({ id: cache.identify(row.original) });
              cache.gc();
            }
          },
        });
      });
    },
  }];

  const onTableParamsChange: ParamsChangeFn = async (filters, pagination, sorting) => {
    let sorts = {};
    if (sorting.length > 0) {
      sorts = { [sorting[0].id]: sorting[0].desc ? 'DESC' : 'ASC' };
    }

    dispatch(tableViewSlice.actions.setRuitsSummaryView({ filters, sorting }));

    getRUITs({
      variables: {
        input: {
          filters: _.mapValues(_.keyBy(filters, 'id'), 'value'),
          pagination: {
            offset: pagination.pageIndex * pagination.pageSize,
            limit: pagination.pageSize,
          },
          sorts,
        },
      },
    });
  };

  const handleCreate = (input: CreateRUITInput) => {
    createRUIT({
      variables: { input },
      onCompleted: data => {
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: 'RUIT successfully created',
        });
        navigate(`/runs/ruit/${data.ruit?.id}`);
      },
      onError: e => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Error deleting RUIT: ${e.message}`,
        });
      },
    });
  };

  const handleClone = () => {
    if (!cloneSource) return;
    cloneRUIT({
      variables: {
        id: cloneSource.id,
        name: cloneName.current?.value || `${cloneSource.name}@${Date.now()}`,
        orgName: cloneSource.organization_name,
        orgId: cloneSource.organization_id,
        teamName: cloneSource.team_name,
        teamId: cloneSource.team_id,
        series: cloneSource.series,
      },
      onCompleted: () => {
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: 'Successfully cloned RUIT',
        });
      },
      onError: e => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Failed to clone RUIT: ${e.message}`,
        });
      },
      refetchQueries: ['RUITManagement'],
    });
    setCloneModalOpen(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setSelectItem = (item: any, name: string) => {
    const clone = cloneDeep(cloneSource);

    if (clone) {
      if (name === 'series') {
        clone.series = item.value;
      }

      if (name === 'org') {
        clone.organization_name = item.value;
        clone.organization_id = item.id;

        // Reset team selections if org changes.
        const findTeam = find(teams, team => team.name === cloneSource?.team_name);
        if (findTeam && findTeam.organization.name !== item.value) {
          clone.team_name = null;
          clone.team_id = null;
        }
      }

      if (name === 'team') {
        clone.team_name = item.value;
        clone.team_id = item.id;
      }

      setCloneSource(clone);
    }
  };

  return (
    <>
      <div className={styles.actionsHeader}>
        <H3>RUITs</H3>
        <Button
          icon="plus"
          intent={Intent.PRIMARY}
          onClick={() => setCreateModalOpen(true)}
          text="Create RUIT"
        />
      </div>
      <Table
        id="ruit-summary"
        bulkRowActions={bulkRowActions}
        columns={columns}
        data={tableData}
        persistColumnVisibility
        enableHiding
        enablePagination
        enableRowSelection
        manualFiltering
        manualPagination
        manualSorting
        initialColumnFilters={tableFilters}
        initialSorting={tableSorting}
        onParamsChange={onTableParamsChange}
        rowActions={rowActions}
        totalRowCount={data?.ruits.totalCount}
      />
      <Dialog
        className={classNames({ 'bp4-dark': darkMode })}
        isOpen={isCloneModalOpen}
        onClose={() => setCloneModalOpen(false)}
        title={`Cloning from "${cloneSource?.name}"`}
      >
        <DialogBody>
          <div>
            <div style={{ paddingBottom: '5px' }}>Organization</div>
            <Select
              initialItem={{ label: cloneSource?.organization_name || 'None', value: cloneSource?.organization_name || null }}
              items={organizationSelectItems(organizations)}
              noSelectionText="Organization"
              onChange={item => setSelectItem(item, 'org')}
            />
          </div>
          <div style={{ paddingTop: '10px' }}>
            <div style={{ paddingBottom: '5px' }}>Team</div>
            <Select
              initialItem={{ label: cloneSource?.team_name || 'None', value: cloneSource?.team_name || null }}
              items={teamSelectItems(teams, cloneSource?.organization_name)}
              noSelectionText="Team"
              onChange={item => setSelectItem(item, 'team')}
            />
          </div>
          <div style={{ paddingTop: '10px' }}>
            <div style={{ paddingBottom: '5px' }}>Series</div>
            <Select
              initialItem={seriesItems.find(i => cloneSource?.series === i.value)}
              items={seriesItems}
              noSelectionText="Series"
              onChange={item => setSelectItem(item, 'series')}
            />
          </div>
          <div style={{ paddingTop: '10px' }}>
            <div style={{ paddingBottom: '5px' }}>Name</div>
            <InputGroup
              placeholder="Enter new SUIT name"
              inputRef={cloneName}
              defaultValue={`${cloneSource?.name} CLONE`}
            />
          </div>
        </DialogBody>
        <DialogFooter
          actions={(
            <>
              <Button
                text="Cancel"
                onClick={() => setCloneModalOpen(false)}
              />
              <Button
                intent="primary"
                text="Clone"
                onClick={() => handleClone()}
              />
            </>
          )}
        />
      </Dialog>
      <CreateRUITDialog
        isOpen={isCreateModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onOk={handleCreate}
      />
    </>
  );
};
