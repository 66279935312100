import { H3, Intent, Button } from '@blueprintjs/core';
import styles from './index.module.css';
import { useState } from 'react';
import MetricsTable from './MetricsTable';

export default () => {
  const [isAddMetricDialogOpen, setIsAddMetricDialogOpen] = useState<boolean>(false);

  return (
    <>
      <div className={styles.actionsHeader}>
        <H3>Metrics Summary</H3>
        <Button
          icon="plus"
          intent={Intent.PRIMARY}
          onClick={() => setIsAddMetricDialogOpen(true)}
          text="Create Metric"
        />
      </div>
      <MetricsTable
        isAddMetricDialogOpen={isAddMetricDialogOpen}
        onAddMetricDialogClose={() => setIsAddMetricDialogOpen(false)}
      />
    </>
  );
};
